.creator-wrapper {
  width: 60%;
  height: auto;
  background-color: #EFEFEF;
  background: #DCDCDC;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
  transition: all ease 0.3s;
  border-radius: 3px;
  overflow: hidden;
  animation: zoom-in 1s ease;
}

.creator-header {
  background-color: black;
  display: flex;
  color: white;
  justify-content: space-between;
  padding: 10px 30px;
  font-size: 15pt;
}

.creator-header .register {
  color: white;
  text-align: right;
}

.creator-profile {
  background-color: white;
  display: flex;
  padding: 20px;
}

.creator-profile .creator-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.creator-profile .creator-info .creator-name {
  font-size: 16pt;
  margin-right: 10px;
}

.creator-profile .creator-info a {
  color: black;
}

.creator-profile .creator-info div {
  margin: 5px 0px;
}

.creator-profile .info-top {
  font-weight: bold;
}

.creator-profile .creator-withdraw {
  font-weight: bold;
  display: block;
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
}

.creator-profile img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-right: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.57);
}

.creator-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 50px;
  background: #E5E5E5;
}

.creator-middle .middle-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.creator-middle .middle-text {
  background: #dbdbdb;
  padding: 5px;
  min-height: 200px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  margin: 0 15px;
  padding: 20px;
  color: #141414;
  overflow-y: auto;
  line-height: 1.5em;
}

.creator-middle .middle-text a {
  color: #141414;
}

.creator-middle .middle-text ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: underline;
  font-size: 12pt;
  display: flex;
  flex-direction: column;
}

.creator-middle .middle-text ul li {
  margin-bottom: 7px;
}

.creator-middle .creator-links {
  flex: 1;
}

.creator-middle .creator-links .middle-text {
  min-width: 250px;
}

.creator-middle .creator-explain {
  flex: 2;
}

.creator-actions {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.creator-actions.dark {
  background: black;
  color: white;
  font-size: 15pt;
  padding: 10px 0px;
}

.creator-actions * {
  flex: 1;
  height: 45px;
}

.creator-actions .button-wrapper {
  display: flex;
  width: 100%;
}

.creator-actions .input-wrapper {
  display: flex;
  width: 100%;
  font-size: 100pt;
}

.creator-actions .input-wrapper * {
  font-size: 13pt;
  flex: 1;
}

.creator-actions button {
  border: none;
  color: white;
  font-size: 15pt;
  transition: all ease 0.1s;
  flex: 1;
}

.creator-actions button:hover {
  filter: brightness(90%);
}

.creator-actions .btn-deposit {
  background-color: #49CD66;
}

.creator-actions .btn-withdraw {
  background-color: #B23E3E;
}

.creator-actions .balance-amount {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  background-color: #c8c8c8;
  text-decoration: underline;
  cursor: pointer;
  color: #000;
}

.creator-actions input {
  border: none;
  background-color: #c8c8c8;
  font-size: 16pt;
  padding-left: 20px;
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
