.loading-wrapper {
  display: -ms-flexbox;
  display: relative;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.17);
  padding: 2.5em;
  width: 171px;
  height: 109px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: zoom-in 1s ease;
}

.loading-wrapper.stretch {
  width: auto;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.loading {
  font-size: 18pt;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.loading.stretch {
  width: 500px;
}

.loading span {
  color: white;
  font-weight: bold;
}

.loading img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.loading:after {
  content: '';
  position: absolute;
  bottom: -5px;
  height: 3px;
  animation: slide 2s ease-in-out;
  animation-iteration-count: infinite;
  background: white;
}

@keyframes zoom-in {
  from {
    font-size: 0pt;
    opacity: 0;
    width: 0;
    height: 0;
  }
  to {
    width: 171px;
    height: 109px;
  }
}

@keyframes slide {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    right: 0;
    width: 100%;
  }
  90% {
    right: 0;
    width: 0;
  }
  100% {
    right: 0;
    width: 0;
  }
}

.fact-wrapper {
  width: 65%;
  color: #171717;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.did-you-know {
  font-weight: bold;
  font-size: 12pt;
  color: #1e1e1e;
}

.fact {
  font-size: 12pt;
  color: #1e1e1e;
}

.loading-message {
  position: fixed;
  bottom: 15px;
  text-align: center;
  opacity: 0;
  width: 90%;
  transition: all ease 0.5s;
  transform: translateY(5px);
}

.loading-message span {
  text-align: center;
  font-size: 11pt;
  color: black;
}

.loading-message.visible {
  transform: translateY(0px);
  opacity: 1;
}
