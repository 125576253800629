* {
  box-sizing: border-box;
  font-family: 'Karla', sans-serif;
}

html {
  background-size: 500% 500%;
  background: linear-gradient(270deg, #e1a0f7, #0095ff);
  background-size: 400% 400%;
  animation-name: example;
  animation-duration: 20s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
}

body, #root, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.demo-link {
  position: absolute;
  z-index: 5;
  top: 30px;
  color: black;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

/* The animation code */
@keyframes example {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
