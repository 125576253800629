.creator-form {
  background: white;
  padding: 30px 20px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  max-width: 500px;
  max-height: 650px;
  overflow-y: auto;
  animation: zoom-in 1s ease;
}

button {
  width: 100%;
  padding: 10px;
  font-size: 16pt;
  background-color: #49CD66;
  border: none;
}

.notice {
  margin-top: 15px;
  max-width: 100%;
  display: block;
  text-align: center;
  font-size: 11pt;
}

button:hover {
  filter: brightness(90%);
}

.input-section {
  margin-bottom: 50px;
}

.input-section input {
  font-size: 16pt;
  width: 100%;
  padding: 10px;
  background: #E5E5E5;
  border: none;
}

.input-section .link-input {
  margin-bottom: 10px;
}

.input-section .btn-new-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-section textarea {
  font-size: 16pt;
  width: 100%;
  min-height: 90px;
  padding: 10px;
  background: #E5E5E5;
  border: none;
}

.input-section .label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  font-size: 15pt;
}

.input-section .label-wrapper svg {
  margin-right: 10px;
}

.input-section .label-wrapper label {
  font-weight: bold;
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
