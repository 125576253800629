@media only screen and (max-width: 1119px) {
  .creator-middle {
    flex-direction: column;
  }
  .middle-section {
    min-width: 100%;
  }
  .middle-section .middle-text {
    min-width: 100%;
  }
  .creator-actions {
    flex-direction: column;
  }
  .creator-actions input {
    -ms-flex-order: 1;
    padding: 10px;
  }
  .creator-actions .balance-amount {
    -ms-flex-order: 0;
    padding: 10px;
  }
  .creator-actions button {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .creator-actions * {
    width: 100%;
  }
  .creator-header {
    flex-direction: column;
  }
  .creator-header * {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .creator-wrapper {
    width: 80% !important;
  }
  .page-wrapper {
    align-items: flex-start;
    margin: 20px 0px;
  }
  .page-wrapper .loading-wrapper {
    margin: auto;
  }
}
